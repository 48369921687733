/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding-bottom: 0;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #f5f5f5;
}

button.bottom-center-fab {
  position: fixed;
  top: 88%;
  left: 42%;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.center-justify {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  align-items: center;
  margin: 5px;
}

/* Alinhamentos de texto */
.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.inline-block {
  display: inline-block;
}

/* Posicionamento */

.float-right {
  float: right;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-10 {
  padding-top: 10px;
}

/* Tamanhos */
.w95 {
  width: 95%;
}

mat-form-field.s-50 {
  width: 50vw;
}

mat-form-field.s-42 {
  width: 42vw;
}

mat-form-field.s-40 {
  width: 40vw;
}

mat-form-field.s-90 {
  width: 90vw;
}

mat-form-field.s-100 {
  width: 100%;
}

/* Tipografia */
.bold {
  font-weight: bold;
}

mat-form-field.mat-form-field {
  font-size: 16px;
}

/* Estilização */
mat-toolbar.dark {
  background-color: #5f5f5f;
  padding: 0px;
}

mat-toolbar.white {
  background-color: #f5f5f5;
}

mat-toolbar.white h2 {
  color: black;
}

mat-toolbar.title h1 {
  width: 100%;
}

mat-toolbar a {
  color: white;
  font-size: 22px;
  cursor: pointer;
  text-decoration: none;
}

mat-toolbar h1 {
  color: white;
  font-size: 22px;
  text-align: center;
  width: calc(100% - 3rem);
}

mat-toolbar h2 {
  font-size: 16px;
  width: 100%;
  text-align: center;
  color: white;
}

mat-toolbar.filter-toolbar {
  padding: 0em;
  width: 100vw;
  margin-left: -16px;
  margin-top: -1.3vh;
  align-content: center;
}

mat-toolbar.filter-toolbar>h1 {
  padding-left: 10vw;
  text-align: center;
}

.green-white-content {
  background-color: #35845e;
  color: white;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgb(29, 82, 0);
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

a.circle {
  position: relative;
  float: left;
}

.circle-aprovado {
  background-color: #35845e;
}

.apontamentos {
  background-color: #e2d009;
}

.adulteracao {
  background-color: #ffa152;
}

.reprovado {
  background-color: #f74e4d;
}

.apontamento-nao-aplicavel {
  /* border: 1px solid lightgray; */
  background-color: rgb(0, 0, 0);
}

.sem-adulteracao {
  background-color: #35845e;
}

.aprovado {
  font-weight: bold;
  color: #35845e;
}

.situacao-reprovado {
  font-weight: bold;
  color: #f74e4d;
}

.aprovado-com-restricao {
  font-weight: bold;
  color: #e2d009;
}

.pendente-de-aprovacao {
  font-weight: bold;
  color: #000000;
}

.blue {
  color: dodgerblue;
}

.tarja {
  text-align: center;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
}

.tarja-small {
  width: 50vw;
  height: 3vh;
  line-height: 3vh;
}

.tarja-big {
  height: 4vh;
  line-height: 4vh;
}

.tarja-padrao {
  background-color: goldenrod;
}

.tarja-aprovado {
  background-color: forestgreen;
  color: white;
}

.tarja-reprovado {
  background-color: crimson;
  color: white;
}

.tarja-apontamento {
  background-color: orange;
  color: white;
}

.tarja-naoAplicavel {
  background-color: black;
  color: white;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.custom-dialog-container .mat-dialog-container {
  opacity: 1;
  padding: 0;
}

.alerta-campo-vazio {
  padding: 20px;
  background: yellow;
  color: black;
  display: flex !important;
  justify-content: center !important;
  margin: auto !important;
  bottom: auto !important;
  align-items: center !important;
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  z-index: 9999;
}

.alerta-nao-aprovado {
  padding: 20px !important;
  background: red !important;
  color: white !important;
  display: flex !important;
  justify-content: center !important;
  margin: auto !important;
  bottom: auto !important;
}

.alerta-aprovado {
  padding: 20px;
  background: green;
  color: white;
  display: flex !important;
  justify-content: center !important;
  margin: auto !important;
  bottom: auto !important;
}

.dialog-camera-custom {
  width: 100vw !important;
  max-width: 100vw !important;
  box-sizing: border-box;
}

.dialog-camera-custom>.mat-dialog-container,
.dialog-video-custom>.mat-dialog-container {
  padding: 0;
}


.dialog-video-custom {
  width: 100vw !important;
  max-width: 100vw !important;
  box-sizing: border-box;
}

.slider-zoom > .mat-slider:hover .mat-slider-track-background, .cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.486) !important;
}

.slider-zoom > .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background-color: #ffab40;
}

.slider-zoom > .mat-slider-horizontal {
  min-width: 220px;
}
